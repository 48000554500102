import i18n from 'sgx-localisation-service';
import { URLUtils } from 'sgx-base-code';
import {
  setLanguage
} from './storage-utils';

export const loginErrorMessage = ({ method = '', status = '', reason = '', params = {} }) => {
  const specificError = reason
    ? `sgx-login.error.${method}.reason.${reason.toLowerCase().replace(/_/g, '-')}`
    : `sgx-login.error.${method}.${status}`;
  const defaultError = 'sgx-login.error.default';

  return i18n.i18next.exists(specificError)
    ? i18n.getTranslation(specificError, params)
    : i18n.getTranslation(defaultError);
};

export const isValidLanguage = (lang) => {
  return ['en', 'zh-cn', 'zh', 'cn', 'ko'].indexOf(lang) > -1;
};

export const normalizeLanguage = (lang) => {
  if (['zh', 'cn'].indexOf(lang) > -1) {
    return 'zh-cn';
  }

  return lang;
};

export const setLanguageFromQueryParams = () => {
  const params = URLUtils.decodeQueryString(window.location.search);
  const lang = isValidLanguage(params.lang)
    ? normalizeLanguage(params.lang)
    : 'en';

  setLanguage(lang);
};
