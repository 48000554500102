export const FPDS_SESSION_STORAGE_KEY = 'sgx-fpds-session-store';
export const FPDS_LOCAL_STORAGE_KEY = 'sgx-fpds-local-store';

export const getSession = () => {
  const session = sessionStorage.getItem(FPDS_SESSION_STORAGE_KEY);
  return session
    ? JSON.parse(session)
    : {};
};

const getLocalStorage = () => {
  const storage = localStorage.getItem(FPDS_LOCAL_STORAGE_KEY);
  return storage
    ? JSON.parse(storage)
    : {};
};

const getProperty = (target, propertyName) => {
  return target.hasOwnProperty(propertyName)
    ? target[propertyName]
    : null;
};

const setSessionProperty = (propertyName, propertyValue) => {
  const session = getSession();
  session[propertyName] = propertyValue;
  sessionStorage.setItem(FPDS_SESSION_STORAGE_KEY, JSON.stringify(session));
};

export const setSessionProperties = (properties) => {
  const session = getSession();
  Object.keys(properties).forEach(propertyName => {
    const propertyValue = properties[propertyName];
    session[propertyName] = propertyValue;
  });

  sessionStorage.setItem(FPDS_SESSION_STORAGE_KEY, JSON.stringify(session));
};

const setLocalStorageProperty = (propertyName, propertyValue) => {
  const storage = getLocalStorage();
  storage[propertyName] = propertyValue;
  localStorage.setItem(FPDS_LOCAL_STORAGE_KEY, JSON.stringify(storage));
};

export const getLanguage = () => getProperty(getSession(), 'lang');

export const setLanguage = (lang) => {
  setSessionProperty('lang', lang);
};

export const isAuthenticated = () => getProperty(getSession(), 'authenticated');

export const setAuthenticated = (authenticated) => {
  setSessionProperty('authenticated', authenticated);
};

export const getSingpassAuthToken = () => getProperty(getSession(), 'singpassAuthToken');

export const setSingpassAuthToken = (singpassAuthToken) => {
  setSessionProperty('singpassAuthToken', singpassAuthToken);
};

export const getLoginSource = () => getProperty(getLocalStorage(), 'loginSource');

export const setLoginSource = (loginSource) => {
  setLocalStorageProperty('loginSource', loginSource);
};

export const getSingpassRedirect = () => getProperty(getLocalStorage(), 'singpassRedirect');

export const setSingpassRedirect = (singpassRedirect) => {
  setLocalStorageProperty('singpassRedirect', singpassRedirect);
};

export const getClientId = () => getProperty(getSession(), 'clientId');

export const setClientId = (clientId) => {
  setSessionProperty('clientId', clientId);
};

export const getSignature = () => getProperty(getSession(), 'Signature');

export const setSignature = (signature) => {
  setSessionProperty('Signature', signature);
};

export const clearStorage = () => {
  sessionStorage.removeItem(FPDS_SESSION_STORAGE_KEY);
  localStorage.removeItem(FPDS_LOCAL_STORAGE_KEY);
  sessionStorage.clear();
};
