import { withInitDOM } from 'sgx-base-code';

/**
 * Generic error page template.
 */
class TemplateErrorPage extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.innerHTML = '<div class="pages-content"></div>';
    this._pageContent = this.querySelector('.pages-content');
  }

  setData(data) {
    this._pageContent.innerHTML = data.pageData.body;
  }

  setConfig() {
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-error-page', withInitDOM(TemplateErrorPage));
