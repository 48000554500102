import { URLUtils } from 'sgx-base-code';

/**
 * @desc Deletes a list of query params from the current url and updates the url state.
 * @param {Array} paramsToDelete the query params to delete
 */
export const deleteQueryParams = (paramsToDelete = []) => {
  let params = URLUtils.decodeQueryString(window.location.search);
  const hash = window.location.hash;
  paramsToDelete.forEach(param => delete params[param]);
  params = URLUtils.encodeQueryString(params);
  window.history.replaceState({}, null, `${window.location.pathname}${hash}${params.length > 1 ? params : ''}`);
};

