import { Utils } from 'sgx-base-code';
import logoImage from '../../assets/images/sgx_logo.png';
import TemplateConsentPage from '../template-consent-page/template-consent-page';
import template from './template-consent-terms-page.html';

class TemplateConsentTermsPage extends TemplateConsentPage {
  constructor() {
    super();
    this._isInitialized = false;
    this._whenReady = Utils.deferred();
  }

  connectedCallback() {
    if (!this._isInitialized) {
      this.updateTemplate(template.getNode());
      this._purposeElement = this.querySelector('[id="Definition of Purpose"]');
      this._logoImageLink = this.querySelector('.sgx-fpds-consent-logo');
      this._logoImageLink.style.backgroundImage = `url(${logoImage})`;
      this._whenReady.resolve();
      this._isInitialized = true;
    }
    if (window.location.hash === '#Definition%20of%20Purpose') {
      this._purposeElement.scrollIntoView(true);
    }
  }

  get template() {
    return template;
  }
}

customElements.define('template-consent-terms-page', TemplateConsentTermsPage);
export default TemplateConsentTermsPage;
