import template from './template-page-base.html';

class TemplatePageBase extends HTMLElement {
  constructor() {
    super();

    this.updateTemplate = this.updateTemplate.bind(this);
  }

  initDOM() {
    this.appendChild(template.getNode());
    this.pageContent = this.querySelector('.pages-content');
  }

  updateTemplate(node) {
    if (!this.pageContent) {
      this.initDOM();
    }
    this.pageContent.appendChild(node);
  }

  setConfig() { }
  setData() { }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('template-page-base', TemplatePageBase);
export default TemplatePageBase;
