'use strict';
/* eslint-disable global-require */

// Base code needs to be first to load polyfills
require('@sgx/sgx-base-code');
import './styles.scss';

import SgxDeviceService from '@sgx/sgx-device-service';
import SgxConfigService from '@sgx/sgx-config-service';
import i18n from '@sgx/sgx-localisation-service';
import { i18nConfig } from './i18n-config';
import {getLanguage, FPDS_SESSION_STORAGE_KEY, FPDS_LOCAL_STORAGE_KEY} from 'utils/storage-utils';
import {deleteQueryParams} from 'utils/query-param-utils';
import {setLanguageFromQueryParams} from 'utils/common-utils';

SgxDeviceService.whenReady({ allowUnsupported: false })
  .then(function startConfigService() {
    return SgxConfigService.whenReady('./config/appconfig.js');
  }, function showUnsupportedMessage() {
    document.body.setAttribute('class', 'no-browser-support');
    document.querySelector('.website-main-layout.sgx-header-layout-target').style.display = 'none';
    return Promise.reject();
  })
  .then(function parseQueryParams() {
    setLanguageFromQueryParams();
    deleteQueryParams(['theme', 'lang', 'webview']);
  })
  .then(function startLocalisationService() {
    return i18n.init(i18nConfig(getLanguage()));
  })
  .then(function loadDependencies() {
    require('sgx-consent-banner');
    require('sgx-app-router');
    require('./templates/template-error-page/template-error-page.js');
    require('./templates/template-consent-page/template-consent-page.js');
    require('./templates/template-service-coming-soon/template-service-coming-soon.js');
    require('./templates/template-consent-terms-page/template-consent-terms-page.js');
    require('./templates/template-data-types/template-data-types.js');

    return Promise.resolve();
  })
  .then(function loadApp() {
    var App = require('./App.js');
    window._sgxComApp = new App();
  });
