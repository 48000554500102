import { Utils } from 'sgx-base-code';
import logoImage from '../../assets/images/sgx_logo.png';
import TemplatePageBase from '../template-page-base/template-page-base';
import template from './template-data-types.html';

class TemplateDataTypes extends TemplatePageBase {
  constructor() {
    super();
    this._isInitialized = false;
    this._whenReady = Utils.deferred();
  }

  connectedCallback() {
    if (!this._isInitialized) {
      this.updateTemplate(template.getNode());
      this._logoImageLink = this.querySelector('.sgx-fpds-consent-logo');
      this._logoImageLink.style.backgroundImage = `url(${logoImage})`;
      this._whenReady.resolve();
      this._isInitialized = true;
    }
  }

  get template() {
    return template;
  }
}

customElements.define('template-data-types', TemplateDataTypes);
export default TemplateDataTypes;
