import ConfigService from 'sgx-config-service';

/**
 * Creates a config to initialize the sgx-localisation-service with.
 * @param {string} targetLang the language to initialize with
 * @returns {Object} the config to initialize i18n with
 */
const i18nConfig = targetLang => {
  // Ensure target language is valid
  const lang = ['en', 'zh-cn'].indexOf(targetLang) === -1 ? 'en' : targetLang;

  // Set the html lang attribute
  document.documentElement.lang = lang;

  // Return the i18n config
  return {
    fallbackLng: ['en'],
    debug: ConfigService.DEV,
    lng: lang,
    backend: { loadPath: './locales/{{lng}}.json' }
  };
};

export {
  i18nConfig
};
