'use strict';

import { Utils } from 'sgx-base-code';
import { Events as RouterEvents } from '@sgx/sgx-app-router';
import routerConfig from '../AppRouteConfig.js';
import routes from '../routes.json';

const payload = routes.reduce((payloadBuilder, route) => {
  if (!route.payload) {
    payloadBuilder[route.name] = route.forwardTo;
    return payloadBuilder;
  }
  payloadBuilder[route.name] = route.payload;
  return payloadBuilder;
}, {});


class SgxRoutingListener {
  constructor(app, router) {
    this._app = app;
    this._router = router;

    this._animationPromise = null;
    this._pageTransitionStart = this._pageTransitionStart.bind(this);
    this._pageTransitionSuccess = Utils.debounce(this._pageTransitionSuccess, 200, this);
    this._pageTransitionError = Utils.debounce(this._pageTransitionError, 200, this);
    this._pageTransitionDone = Utils.debounce(this._pageTransitionDone, 200, this);
  }

  start() {
    this._router.addEventListener(RouterEvents.TRANSITION_START, this._pageTransitionStart);
    this._router.addEventListener(RouterEvents.TRANSITION_SUCCESS, this._pageTransitionSuccess);
    this._router.addEventListener(RouterEvents.TRANSITION_ERROR, this._pageTransitionError);
    this._router.init(routerConfig);
  }

  _pageTransitionStart() {
    const self = this;
    this._animationPromise = new Promise(function (resolve, reject) { // eslint-disable-line
      self._app.mainContainer.classList.add('router-transition-in-progress');
      self._app.mainContainer.addEventListener('transitionend', resolve);
    });
  }

  _pageTransitionSuccess(event) {
    var newState = event.detail.newState;
    this._createPageData(newState);
    var self = this;

    if (this._animationPromise) {
      this._animationPromise.then(function() {
        self._app.updateMetaData(newState);
        self._app.updateTemplate(newState, 'headerTemplateId', self._app.pageHeader);
        self._app.updateTemplate(newState, 'templateId', self._app.pageContent);
        self._pageTransitionDone();
      });
    } else {
      self._app.updateMetaData(newState);
      self._app.updateTemplate(newState, 'headerTemplateId', self._app.pageHeader);
      self._app.updateTemplate(newState, 'templateId', self._app.pageContent);
      self._pageTransitionDone();
    }
  }

  _pageTransitionError(event) {
    if (event.detail && event.detail.error && event.detail.error.code !== 'SAME_STATES') {
      console.error('Page transition error:', event.detail);
      if (this._animationPromise) {
        this._animationPromise.then(this._pageTransitionDone);
      }
    }
  }

  _pageTransitionDone() {
    this._animationPromise = null;
    this._app.mainContainer.classList.remove('router-transition-in-progress');
  }

  _createPageData(state) {
    const name = state.name;
    let templateData = payload[name];
    if (typeof templateData === 'string') {
      templateData = payload[templateData];
    }

    if (templateData != null) {
      state.pageData = templateData;
    } else {
      state.pageData = payload['404'];
    }
  }
}

export default SgxRoutingListener;
