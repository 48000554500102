'use strict';

import i18n from '@sgx/sgx-localisation-service';
import {
  isAuthenticated
} from 'utils/storage-utils';
import SgxRoutingListener from './services/sgx-routing-listener';

const BASE_TITLE = document.title || 'SGX';

class App {
  constructor() {
    this.router = null;
    this.pageHeader = null;
    this.pageContent = null;

    this.init();
  }

  init() {
    this.mainContainer = document.getElementById('main-container');
    this.pageHeader = document.getElementById('page-header-container');
    this.pageContent = document.getElementById('page-container');
    this.globalStatusIndicator = document.querySelector('#website-main-loader');
    this.globalStatusIndicator.style.display = 'none';

    this.router = document.getElementById('sgx-app-router');
    this.routingListener = new SgxRoutingListener(this, this.router);
    this.routingListener.start();

    if (window.location.pathname === '/bouncer') {
      return this.router.navigateToURL('/bouncer');
    }

    if (!isAuthenticated()) {
      window.location.href = 'login.html';
    } else {
      this.router.navigateToURL('/consent');
    }
    return null;
  }

  updateMetaData(newState) {
    if (!newState.pageData) {
      return;
    }

    let pageTitle = newState.pageData.pageTitle;
    if (newState.pageData.translate) {
      pageTitle = i18n.getTranslation(pageTitle);
    }
    document.title = pageTitle ? (pageTitle + ' - ' + BASE_TITLE) : BASE_TITLE;
  }

  updateTemplate(newState, id, target) {
    var templateId = newState.pageData[id];

    try {
      while (target.lastChild) {
        target.removeChild(target.lastChild);
      }
    } catch (ex) {
      console.error(ex.stack);
    }

    if (!newState.pageData[id]) {
      return;
    }

    try {
      var template = this._getTemplate(target, templateId);
      if (template) {
        template.setData(newState.pageData, newState.params);
        window.requestAnimationFrame(function() {
          template.show();
        });
      }
    } catch (ex) {
      console.error(ex.stack);
    }
  }

  _getTemplate(parent, id) {
    if (!id) {
      return null;
    }

    var template = parent.querySelector(id);

    if (!template) {
      template = document.createElement(id);
      parent.appendChild(template);
    }

    return template;
  }
}

module.exports = App;
