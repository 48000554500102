import { Utils } from 'sgx-base-code';
import '@sgx/sgx-status-indicator';
import TemplatePageBase from '../template-page-base/template-page-base';
import template from './template-service-coming-soon.html';

class TemplateServiceComingSoonPage extends TemplatePageBase {
  constructor() {
    super();
    this._isInitialized = false;
    this._whenReady = Utils.deferred();
  }

  connectedCallback() {
    if (!this._isInitialized) {
      this.updateTemplate(template.getNode());
      this._indicator = this.querySelector('sgx-status-indicator');
      this._whenReady.resolve();
      this._isInitialized = true;
    }
  }

  get template() {
    return template;
  }
}

customElements.define('template-service-coming-soon-page', TemplateServiceComingSoonPage);
export default TemplateServiceComingSoonPage;
