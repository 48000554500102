module.exports = (function() {
    var templateString = module.exports = "<template id=\"template-data-types\">\n  <div class=\"row\">\n    <div class=\"sgx-fpds-consent-logo-container\">\n      <a class=\"sgx-fpds-consent-logo\"></a>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"sgx-fpds-consent-content\">\n      <style>\n        .sgx-data-types {\n          line-height: 1.5rem;\n        }\n        .sgx-data-types ol {\n          margin-top: 1rem;\n          padding-left: 2rem;\n          list-style-type: circle;\n        }\n        .sgx-data-types > ol li {\n          margin-bottom: 1rem;\n        }\n      </style>\n      <div class=\"sgx-data-types\">\n        <h2 class=\"sgx-base-text-h2\">Direct holding information from CDP:</h2>\n        <ol class=\"sgx-data-types-list\">\n          <li>Shares</li>\n          <li>REITs</li>\n          <li>ETFs</li>\n          <li>Depository Receipts</li>\n          <li>Bonds (incl. SGS and SSBs)</li>\n          <li>Structured Products (e.g. DLCs, Warrants)</li>\n          <li>Any other direct holdings with CDP (i.e. excluding SRS and CPFIS)</li>\n        </ol>\n      </div>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()