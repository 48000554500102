/**
 * @module AppRouter Config
 * @desc Application Router Configuration, used by router5
 */

import routes from './routes.json';

export default {
  routes,
  externalNewTab: true,
  routerOptions: {
    defaultRoute: 'home',
    defaultParams: {first: 'home'},
    strictTrailingSlash: false // to cater for /securities, not only investors.sgx.com/securities/
  }
};
